import {Component} from 'react';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * ExternalLink
 *
 * @class ExternalLink
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class ExternalLink extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        href: PropTypes.string,
        testId: PropTypes.string
    }

    static defaultProps = {
        href: '',
        testId: null
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof ExternalLink
     */
    render() {
        const {children, href, testId} = this.props;

        return (
            <NextLink href={href} passHref>
                <ExternalLinkElement data-cy={testId} rel="noopener noreferrer" target="_blank">
                    {children}
                </ExternalLinkElement>
            </NextLink>
        );
    }
}

export default ExternalLink;

const ExternalLinkElement = styled.a`
    color: inherit;
    text-decoration: none;
`;