export const breakpoints = {
    lg: {
        max: 1199,
        min: 992
    },
    md: {
        max: 991,
        min: 769
    },
    sm: {
        max: 768,
        min: 576
    },
    xl: {
        max: Infinity,
        min: 1200
    },
    xs: {
        max: 575,
        min: 0
    }
};


/**
 * Sets an media breakpoint.
 *
 * @param {String} size The screen size.
 *
 * @returns {String} The media query.
 */
export const mediaUp = size => `@media (min-width: ${breakpoints[String(size)].min}px)`;

/**
  * Sets an media breakpoint.
  *
  * @param {String} size The screen size.
  *
  * @returns {String} The media query.
  */
export const mediaDown = size => `@media (max-width: ${breakpoints[String(size)].max}px)`;

/**
  * Sets an media breakpoint.
  *
  * @param {String} size The screen size.
  *
  * @returns {String} The media query.
  */
export const mediaBetween = size => `@media (min-width: ${breakpoints[String(size)].min}px) and (max-width: ${breakpoints[String(size)].max}px)`;

/**
  * Sets an vertical media breakpoint.
  *
  * @param {String} size The screen size.
  *
  * @returns {String} The media query.
  */
export const mediaVerticalDown = size => `@media (max-height: ${size}px)`;