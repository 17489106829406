import ReactDOMServer from 'react-dom/server';

/**
 * Parses the Answers array to an usable Object.
 *
 * @param {Array} answers An array of Answer objects.
 *
 * @returns {Object} All answers in one object.
 */
export const parseAnswersToObject = answers => {
    if (!Array.isArray(answers)) {
        throw new TypeError('Param answers is expected to be an Array!');
    }


    return answers.reduce((acc, val) => {
        if (typeof val === 'object' && val !== null) {
            return {
                ...acc,
                ...val
            };
        }

        return acc;
    }, {});
};

/**
 * Parses an svg icon as url image.
 *
 * @param {ReactElement} Icon  An React svg Icon component.
 * @param {Object}       props The Icon props.
 *
 * @returns {String} An html string.
 */
export const iconAsBackground = (Icon, props) => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
)}`;

/**
 * PropType check for an range of numbers.
 *
 * @param {Number} start Startnumber.
 * @param {Number} end   Endnumber.
 *
 * @returns {Function} Proptype check function.
 */
export const checkNumberRange = (start, end) => (props, propName, componentName) => {
    // eslint-disable-next-line no-param-reassign
    componentName = componentName || 'ANONYMOUS';

    // eslint-disable-next-line react/destructuring-assignment
    if (props[String(propName)]) {
        const value = props[String(propName)];

        if (typeof value === 'number') {
            return (value >= start && value <= end)
                ? null
                : new Error(`${propName} in ${componentName} is not within ${start} to ${end}`);
        }
    }

    // assume all ok
    return null;
};