import {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Close} from 'Images/icons';
import {mediaUp} from 'Utils/mediaQueries';

/**
 * CloseButton
 *
 * @class CloseButton
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class CloseButton extends Component {
    static propTypes = {
        testId: PropTypes.string,
        onClick: PropTypes.func
    }

    static defaultProps = {
        onClick() {},
        testId: null
    }

    /**
     * Calls the close handler.
     *
     * @memberof CloseButton
     */
    @autobind
    handleClick() {
        const {onClick} = this.props;

        onClick();
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof CloseButton
     */
    render() {
        const {testId} = this.props;

        return (
            <CloseButtonElement data-cy={testId} onClick={this.handleClick}>
                <Close height={16} width={16} />
            </CloseButtonElement>
        );
    }
}

export default CloseButton;

const CloseButtonElement = styled.button`
    align-items: center;
    background-color: ${({theme}) => theme.colors.lightButtonColor};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    outline: none;
    transition: background-color 0.3s 0s ease-in-out;
    width: 48px;

    &:hover {
        background-color: ${({theme}) => theme.colors.buttonPrimaryBgColor};
        transition: background-color 0.3s 0s ease-in-out;

        & svg g {
            fill: ${({theme}) => theme.colors.lightButtonColor};
            transition: fill 0.3s 0s ease-in-out;
        }
    }

    &:focus, &:active {
        outline: none;
    }

    ${mediaUp('md')} {
        height: 56px;
        width: 56px;
    }
`;