import styled from 'styled-components';

import {mediaUp} from 'Utils/mediaQueries';

const sizes = {
    normal: {
        fontSize: '18px',
        lineHeight: '28px'
    },
    normalCompact: {
        fontSize: '18px',
        lineHeight: '18px'
    },
    small: {
        fontSize: '16px',
        lineHeight: '16px'
    },
    tiny: {
        fontSize: '14px',
        lineHeight: '16px'
    }
};

/**
 * Gets right color.
 *
 * @param {Object}  props                   The component props.
 * @param {Boolean} props.isHighlighted     If its an highlighted element.
 * @param {Boolean} props.isTertiary        If the color should be tertiary.
 * @param {Boolean} props.isInverted        If the colors should be inverted (white).
 * @param {Object}  props.theme             The theme object.
 *
 * @returns {String} The color code.
 */
const getColor = ({isHighlighted, isInfo, isInverted, isTertiary, theme}) => {
    if (isHighlighted) {
        return theme.colors.textColorHighlight;
    } else if (isInfo) {
        return theme.colors.textColorInfo;
    } else if (isInverted) {
        return theme.colors.textColorLight;
    } else if (isTertiary) {
        return theme.colors.textColorAlternate;
    }

    return theme.colors.textColorPrimary;
};


export const H1 = styled.h1.attrs(props => ({'data-cy': props.testId}))`
    color: ${getColor};
    font-family: 'ProximaNova', Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    margin: 0;
    text-align: center;

    ${mediaUp('md')} {
        text-align: ${({align}) => (align || 'left')};
        font-size: 48px;
        line-height: 56px;
    }
`;

export const H2 = styled.h2.attrs(props => ({'data-cy': props.testId}))`
    color: ${getColor};
    font-family: 'ProximaNova', Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    margin: 0;
    text-align: ${({align}) => align};

    ${mediaUp('md')} {
        font-size: 28px;
        line-height: 34px;
    }
`;

export const Paragraph = styled.p.attrs(props => ({'data-cy': props.testId}))`
    color: ${getColor};
    font-family: 'ProximaNova', Arial, sans-serif;
    font-size: 16px;
    font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
    line-height: 22px;
    margin: 0;
    text-align: ${({align}) => align};
    ${({mobileAlign}) => (mobileAlign ? `text-align: ${mobileAlign};` : null)}
    transition: color 0.2s 0s ease-in-out;

    ${mediaUp('md')} {
        text-align: ${({align}) => (align || 'left')};
        font-size: ${({size}) => (size ? sizes[String(size)].fontSize : sizes[String('normal')].fontSize)};
        line-height: ${({size}) => (size ? sizes[String(size)].lineHeight : sizes[String('normal')].lineHeight)};
    }
`;

export const Inline = styled.span.attrs(props => ({'data-cy': props.testId}))`
    color: ${getColor};
    font-family: 'ProximaNova', Arial, sans-serif;
    font-size: ${({size}) => (size ? sizes[String(size)].fontSize : 'inherit')};
    font-weight: ${({bold}) => (bold ? 'bold' : 'inherit')};
    line-height: inherit;
    text-align: ${({align}) => align};
    transition: color 0.2s 0s ease-in-out;
    white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'normal')};

    ${mediaUp('md')} {
        font-size: ${({size}) => (size ? sizes[String(size)].fontSize : 'inherit')};
        line-height: ${({size}) => (size ? sizes[String(size)].lineHeight : 'inherit')};
    }
`;