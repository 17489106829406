import {Component} from 'react';

import {motion} from 'framer-motion';
import styled from 'styled-components';

import {mediaUp} from 'Utils/mediaQueries';
import {ModalContext} from 'Utils/ModalContext';

const Transition = {
    closed: {opacity: 0},
    open: {opacity: 1}
};

/**
 * Modal
 *
 * @augments {Component<Props, State>}
 *
 * @class Modal
 * @extends {Component}
 */
class Modal extends Component {
    static propTypes = {}

    static defaultProps = {}

    static contextType = ModalContext;

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Modal
     */
    render() {
        const {content, isOpen} = this.context;

        return (
            <ModalElement
                animate={isOpen ? 'open' : 'closed'}
                initial="closed"
                isOpen={isOpen}
                variants={Transition}
            >
                {content}
            </ModalElement>
        );
    }
}

export default Modal;

const ModalElement = styled(motion.div)`
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: auto;
    padding-bottom: 26px;
    padding-top: 26px;
    pointer-events: ${({isOpen}) => (isOpen ? 'auto' : 'none')};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;

    ${mediaUp('md')} {
        align-items: center;
        padding-top: 0;
    }
`;