/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * ArrowRight Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const ArrowRight = React.forwardRef((props, ref) => {
    const {className, color, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 16 11"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color}>
                <path d="M15.2 4.65c.25.24.43.54.43.9 0 .71-.54 1.25-1.26 1.25L2 6.74C1.27 6.74.73 6.2.73 5.5c0-.72.54-1.26 1.26-1.26l12.38.06c.36 0 .66.18.84.36z" />
                <path d="m11.14.46 4.13 4.13a1.3 1.3 0 0 1 0 1.8l-4.13 4.12a1.3 1.3 0 0 1-1.8 0 1.3 1.3 0 0 1 0-1.8l3.24-3.22-3.23-3.23a1.3 1.3 0 0 1 0-1.8 1.3 1.3 0 0 1 1.8 0z" />
            </g>
        </svg>
    );
});

ArrowRight.displayName = 'ArrowRight';
ArrowRight.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    testId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
ArrowRight.defaultProps = {
    className: '',
    color: '#289BD7',
    height: 11,
    testId: '',
    width: 16
};