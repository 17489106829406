import {store} from '@risingstack/react-easy-state';

import Tree from 'Classes/Questionairetree';

import {parseAnswersToObject} from 'Utils/lib';

const QuestionaireStore = store({
    answers: [],
    answersIntegration: [],
    get answersObj() {
        return parseAnswersToObject(this.answers);
    },
    get company() {
        const data = parseAnswersToObject(this.answers);

        return data.companyName === 'skip' ? 'Ihr Unternehmen' : data.companyName;
    },
    get isFlatrate() {
        const data = parseAnswersToObject(this.answers);

        return (data.calc_callvolume === 'high');
    },
    get isHardware() {
        const data = parseAnswersToObject(this.answers);

        return data.calc_hardware !== 'false' && typeof data.calc_hardware !== 'undefined';
    },
    get isIntegration() {
        const data = parseAnswersToObject(this.answers);

        return Boolean(data.calc_integrations);
    },
    get isMobile() {
        const data = parseAnswersToObject(this.answers);

        return Boolean(data.calc_mobile);
    },
    get isTeams() {
        const data = parseAnswersToObject(this.answers);

        return Boolean(data.calc_othercollab);
    },
    get isWebex() {
        const data = parseAnswersToObject(this.answers);

        return (data.calc_messaging || data.calc_meeting);
    },
    get next() {
        return Tree.evaluate(this.answers);
    },
    tempCompanyName: '',
    userEmail: '',
    resetAnswers() {
        QuestionaireStore.answers = [];
        QuestionaireStore.answersIntegration = [];
        QuestionaireStore.tempCompanyName = '';
    },
    async sendData(data) {
        const interview = this.answersObj;

        interview.calc_integrations = (Array.isArray(interview.calc_integrations)
            ? interview.calc_integrations.join(', ')
            : interview.calc_integrations);

        // eslint-disable-next-line no-param-reassign
        data.product = this.isWebex ? 'webex' : 'profi';
        // eslint-disable-next-line no-param-reassign
        data.interview = interview;

        const request = await fetch('/api/send-lead', {
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });

        return request.json();
    }
});

export default QuestionaireStore;