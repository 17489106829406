import {email, intNumber} from '@nfq/safe-validation-regex';

import UtilsStore from 'Stores/UtilsStore';

import {breakpoints} from 'Utils/mediaQueries';

const MIN_COMPANY_LENGTH = 2;
const MIN_NAME_LENGTH = 1;
const MIN_COMPANY_SIZE = 1;

/**
 * Validate the form input
 *
 * @param {String} value The value to check.
 * @param {String} name  The field name to check.
 * @returns {String|Null} Returns an error string or null.
 */
export const validateInput = async (value, name) => {
    const {isPossiblePhoneNumber} = (await import(
        /* webpackChunkName: "libphonenumber" */
        'libphonenumber-js/max'
    ));

    switch (name) {
        case 'companyName':
            if (value.length < MIN_COMPANY_LENGTH) {
                return 'Bitte gib einen validen Firmennamen an.';
            }

            return null;
        case 'firstName':
            if (value.length < MIN_NAME_LENGTH) {
                return 'Bitte gib einen validen Vornamen an.';
            }

            return null;
        case 'lastName':
            if (value.length < MIN_NAME_LENGTH) {
                return 'Bitte gib einen validen Nachnamen an.';
            }

            return null;
        case 'email':
            if (!email.test(value)) {
                return 'Bitte gib eine valide E-Mail-Adresse ein (example@email.de).';
            }

            return null;
        case 'phone':
            if (!isPossiblePhoneNumber(value, 'DE')) {
                return 'Bitte gib eine valide Telefonnummer an.';
            }

            return null;
        case 'companySize':
            if (!intNumber.test(value) || Number.parseInt(value, 10) < MIN_COMPANY_SIZE) {
                return 'Bitte gib eine valide Unternehmensgröße an.';
            }

            return null;
        default:
            return null;
    }
};

/**
 * Calculate the index for animation
 *
 * @param {Number} width Window width
 * @param {String} type Type of Button (yes or no)
 * @returns {Number} Index for animation
 */
export const indexNumberForYesNoButtons = (width, type) => {
    let index = 1;

    /* eslint-disable @nfq/no-magic-numbers */
    if (width <= breakpoints.xs.max) {
        index = type === 'no' ? 3 : 2;
    } else if (width >= breakpoints.sm.min && width <= breakpoints.sm.max) {
        index = type === 'no' ? 2 : 3;
    } else if (width >= breakpoints.md.min && width <= breakpoints.md.max) {
        index = type === 'no' ? 3 : 2;
    } else if (width >= breakpoints.lg.min) {
        index = type === 'no' ? 2 : 3;
    }

    return index;
};

/**
 * Sets the scroll to true
 *
 * @param {String} definition Animation def name.
 */
export const handleOnAnimationComplete = definition => {
    if (definition === 'enter') {
        UtilsStore.allowScroll = true;
    }
};