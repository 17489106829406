/* eslint-disable max-lines */
import React, {cloneElement, Component} from 'react';

import {view} from '@risingstack/react-easy-state';
import {autobind} from 'core-decorators';
import {AnimatePresence, motion} from 'framer-motion';
import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressIndicator from 'Components/action/ProgressIndicator';
import Hidden from 'Components/layout/Hidden';
import Modal from 'Components/layout/Modal';
import QuestionaireStore from 'Stores/QuestionaireStore';
import UtilsStore from 'Stores/UtilsStore';

import Navigation from 'Modules/Navigation';

import KarinaSidebar from 'Images/karina_sidebar';
import Sidebar from 'Images/sidebar';
import {mediaUp, mediaVerticalDown} from 'Utils/mediaQueries';


const RIGHT_DELAY = 0.5;
const LEFT_DELAY = 0.5;

const PageLayoutAnim = {
    imageLeft: {},
    imageRight: {}
};

const LeftImageAnim = {
    imageLeft: {
        flexBasis: '32.5%',
        opacity: 1,
        transition: {
            flexBasis: {
                // eslint-disable-next-line @nfq/no-magic-numbers
                delay: LEFT_DELAY + 0.35,
                duration: 0.62,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: LEFT_DELAY,
                duration: 0
            }
        }
    },
    imageRight: {
        flexBasis: '0%',
        opacity: 0,
        transition: {
            flexBasis: {
                delay: RIGHT_DELAY,
                duration: 1,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: RIGHT_DELAY,
                duration: 0.35,
                ease: 'easeInOut',
                type: 'tween'
            }
        }
    },
    noImage: {
        flexBasis: '0%',
        opacity: 0,
        transition: {
            flexBasis: {
                delay: RIGHT_DELAY,
                duration: 1,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: RIGHT_DELAY,
                duration: 0.35,
                ease: 'easeInOut',
                type: 'tween'
            }
        }
    }
};

const SidebarAnim = {
    imageLeft: {
        opacity: 0,
        transition: {
            delay: LEFT_DELAY,
            duration: 0.62,
            ease: 'easeInOut',
            type: 'tween'
        },
        x: '200%',
        y: '-50%'
    },
    imageRight: {
        opacity: 1,
        transition: {
            // eslint-disable-next-line @nfq/no-magic-numbers
            delay: RIGHT_DELAY + 0.35,
            duration: 0.62,
            ease: 'easeInOut',
            type: 'tween'
        },
        x: '0%',
        y: '-50%'
    },
    noImage: {
        opacity: 0,
        transition: {
            delay: 0,
            duration: 0.62,
            ease: 'easeInOut',
            type: 'tween'
        },
        x: '0%',
        y: '-50%'
    }
};

const RightImageAnim = {
    imageLeft: {
        flexBasis: '0%',
        opacity: 0,
        transition: {
            flexBasis: {
                delay: LEFT_DELAY,
                duration: 1,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: LEFT_DELAY,
                duration: 0.35,
                ease: 'easeInOut',
                type: 'tween'
            }
        }
    },
    imageRight: {
        flexBasis: '30.1%',
        opacity: 1,
        transition: {
            flexBasis: {
                // eslint-disable-next-line @nfq/no-magic-numbers
                delay: RIGHT_DELAY + 0.35,
                duration: 0.62,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: RIGHT_DELAY,
                duration: 0
            }
        }
    },
    noImage: {
        flexBasis: '0%',
        opacity: 0,
        transition: {
            flexBasis: {
                delay: LEFT_DELAY,
                duration: 1,
                ease: 'easeInOut',
                type: 'tween'
            },
            opacity: {
                delay: LEFT_DELAY,
                duration: 0.35,
                ease: 'easeInOut',
                type: 'tween'
            }
        }
    }
};

/**
 * PageLayout
 *
 * @class PageLayout
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class PageLayout extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        router: PropTypes.object.isRequired,
        step: PropTypes.number,
        transition: PropTypes.node
    }

    static defaultProps = {
        step: 1,
        transition: 'imageLeft'
    }

    /**
     *Creates an instance of PageLayout.
    * @param {*} props Props
    * @memberof PageLayout
    */
    constructor(props) {
        super(props);

        this.state = {
            isAllowBackClick: true,
            showNav: true
        };

        this.contentElement = React.createRef();

        // eslint-disable-next-line react/destructuring-assignment
        this.oldRouter = this.props.router.route;
    }

    /**
     * Registers an back handler to remove answers from the store.
     *
     * @memberof PageLayout
     */
    componentDidMount() {
        const {router} = this.props;
        const pagesThatResetProcess = ['/', '/danke', '/berechnung', '/ergebnis-webex', '/ergebnis-profi'];

        router.beforePopState(() => {
            if (pagesThatResetProcess.includes(router.route)) {
                QuestionaireStore.resetAnswers();
                router.replace('/');

                return false;
            }

            QuestionaireStore.answers.pop();

            return true;
        });
    }

    /**
     * Handles the component update.
     *
     * @memberof Carousel
     */
    componentDidUpdate() {
        const {router} = this.props;
        const {route} = router;

        if (route !== this.oldRouter) {
            this.oldRouter = route;
            const longerDelay = 800;
            const shortDelay = 550;
            const backAnimationDuration = 1620;

            const delay = route === '/unternehmen' ? longerDelay : shortDelay;

            setTimeout(() => {
                this.scrollToTop();
            }, delay);

            setTimeout(() => {
                this.setState({isAllowBackClick: true});
            }, backAnimationDuration);
        }
    }

    /**
     * Returns the next link to navigate to.
     *
     * @returns {String} Next url to go to.
     * @memberof PageLayout
     */
    @autobind
    next() {
        UtilsStore.allowScroll = false;

        return QuestionaireStore.next;
    }

    /**
     * Handles the back button.
     *
     * @memberof PageLayout
     */
    @autobind
    handleBack() {
        const {router} = this.props;

        UtilsStore.allowScroll = false;

        this.setState({isAllowBackClick: false});

        router.back();
    }

    /**
     * Calc should the navigtaion be shown or hidden.
     *
     * @param {Event} e Event
     * @memberof PageLayout
     */
    @autobind
    handleScrollEvent(e) {
        const threshold = 30;
        const {showNav} = this.state;

        if ((e.target.scrollTop > threshold) && showNav) {
            this.setState({showNav: false});
        } else if ((e.target.scrollTop <= threshold) && !showNav) {
            this.setState({showNav: true});
        }
    }

    /**
     * Scroll Content elemet to top
     *
     * @memberof PageLayout
     */
    @autobind
    scrollToTop() {
        const {current} = this.contentElement;

        current.scrollTop = 0;
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof PageLayout
     *
     * @TODO Change transition back to props after design testing.
     */
    render() {
        const {isAllowBackClick, showNav} = this.state;
        const {children, router, step, transition} = this.props;
        const {allowScroll} = UtilsStore;

        // eslint-disable-next-line no-unused-expressions
        QuestionaireStore.next;

        return (
            <PageWrapper animate={transition} initial="imageLeft" variants={PageLayoutAnim}>
                <Navigation
                    isAllowBackClick={isAllowBackClick}
                    router={router}
                    showNav={showNav}
                    step={step}
                    onHandleBack={this.handleBack}
                />
                <Hidden md sm xs>
                    <SidebarContainer variants={SidebarAnim}>
                        <ProgressIndicator
                            isAllowBackClick={isAllowBackClick}
                            step={step}
                            steps={7}
                            onClick={this.handleBack}
                        />
                    </SidebarContainer>
                </Hidden>
                <LeftImage variants={LeftImageAnim}>
                    <Image
                        alt="Sidebar Image"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="right center"
                        placeholder="blur"
                        src={KarinaSidebar}
                    />
                </LeftImage>
                <Content
                    ref={this.contentElement}
                    allowScroll={allowScroll}
                    variants={PageLayoutAnim}
                    onScroll={this.handleScrollEvent}
                >
                    <AnimatePresence exitBeforeEnter>
                        {cloneElement(children, {
                            contentWrapper: this.contentElement,
                            key: router.pathname,
                            next: this.next
                        })}
                    </AnimatePresence>
                </Content>
                <RightImage variants={RightImageAnim}>
                    <Image
                        alt="Sidebar Image"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="left center"
                        placeholder="blur"
                        src={Sidebar}
                    />
                </RightImage>
                <Modal />
            </PageWrapper>
        );
    }
}

export default view(PageLayout);

const PageWrapper = styled(motion.div)`
    align-items: stretch;
    display: flex;
    height: 100%;
    width: 100%;
`;

const LeftImage = styled(motion.div)`
    display: none;
    flex: 0 0 32.5%;
    height: 100%;
    position: relative;

    ${mediaUp('md')} {
        display: block;
    }
`;

const Content = styled(motion.div).attrs(({allowScroll}) => (
    {style: {overflowY: allowScroll ? 'auto' : 'hidden'}}
))`
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 100%;
`;

const SidebarContainer = styled(motion.div)`
    height: 521px;
    left: 56px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 32px;
    z-index: 20;

    ${mediaVerticalDown('700')} {
        top: calc(50% + 40px);
    }
`;

const RightImage = styled(motion.div)`
    display: none;
    flex: 0 0 0%;
    height: 100%;
    position: relative;

    ${mediaUp('md')} {
        display: block;
    }
`;