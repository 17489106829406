/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * PlacetelLogo Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const PlacetelLogo = React.forwardRef((props, ref) => {
    const {circleColor, className, height, lineColor, testId, textColor, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 145 32"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none">
                <path d="M13.71 27.46A13.72 13.72 0 1 1 13.73.02a13.72 13.72 0 0 1-.02 27.44zm0-19.6a5.87 5.87 0 1 0 .01 11.73 5.87 5.87 0 0 0 0-11.73z" fill={circleColor} />
                <path d="M.03 14.57h7.88V32H.03z" fill={lineColor} />
                <path d="M34 23.88V8.3h5.95c3.39 0 4.93 2.6 4.93 5.2 0 2.62-1.54 5.17-4.93 5.17H37.5v5.2H34zm3.49-12.27v3.82h2.3c1.14 0 1.56-1 1.56-1.92 0-.93-.42-1.9-1.57-1.9h-2.3zm10.26 12.27V8.31h3.53v12.27h5.17v3.3zm21.38-3.58H64.6l-1.21 3.58h-3.74l5.3-15.57h3.8l5.3 15.57h-3.7l-1.22-3.57zm-3.59-3.24h2.7l-1.4-5.13-1.3 5.13zm23.94 3.45a7.2 7.2 0 0 1-6.12 3.57c-4.05 0-7.33-3.59-7.33-7.97 0-4.43 3.28-8 7.33-8a7.23 7.23 0 0 1 6.14 3.57l-2.9 2.19a3.77 3.77 0 0 0-3.24-1.97c-2.13 0-3.8 1.88-3.8 4.2 0 2.33 1.67 4.19 3.8 4.19a3.78 3.78 0 0 0 3.26-1.95l2.86 2.17zm4.22 3.37V8.31h9.34v3.3h-5.85v2.98h5.14v3.23h-5.14v2.76h6.03v3.3zm13.18-12.27v-3.3h10.93v3.3h-3.72v12.27h-3.49V11.61zm15.19 12.27V8.31h9.33v3.3h-5.84v2.98h5.13v3.23h-5.13v2.76h6.03v3.3zm14.22 0V8.31h3.53v12.27H145v3.3z" fill={textColor} />
            </g>
        </svg>
    );
});

PlacetelLogo.displayName = 'PlacetelLogo';
PlacetelLogo.propTypes = {
    circleColor: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    lineColor: PropTypes.string,
    testId: PropTypes.string,
    textColor: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
PlacetelLogo.defaultProps = {
    circleColor: '#009EE2',
    className: '',
    height: 32,
    lineColor: '#6AB32D',
    testId: '',
    textColor: '#222529',
    width: 145
};