/* eslint-disable react/boolean-prop-naming */
import {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {mediaBetween, mediaUp} from 'Utils/mediaQueries';

/**
 * Hidden
 *
 * @class Hidden
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Hidden extends Component {
    static propTypes = {
        children: PropTypes.node,
        lg: PropTypes.bool,
        md: PropTypes.bool,
        sm: PropTypes.bool,
        /** TestID for cypress testing  */
        testId: PropTypes.string,
        xl: PropTypes.bool,
        xs: PropTypes.bool
    }

    static defaultProps = {
        children: null,
        lg: null,
        md: null,
        sm: null,
        testId: null,
        xl: null,
        xs: null
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Hidden
     */
    render() {
        const {
            children,
            lg,
            md,
            sm,
            testId,
            xl,
            xs
        } = this.props;

        return (
            <HiddenElement
                data-cy={testId}
                lg={lg}
                md={md}
                sm={sm}
                xl={xl}
                xs={xs}
            >
                {children}
            </HiddenElement>
        );
    }
}

export default Hidden;

const HiddenElement = styled.div`
    ${({xs}) => (xs ? `${mediaBetween('xs')} {
        display: none;
    }` : null)};

    ${({sm}) => (sm ? `${mediaBetween('sm')} {
        display: none;
    }` : null)};

    ${({md}) => (md ? `${mediaBetween('md')} {
        display: none;
    }` : null)};

    ${({lg}) => (lg ? `${mediaBetween('lg')} {
        display: none;
    }` : null)};
    
    ${({xl}) => (xl ? `${mediaUp('xl')} {
        display: none;
    }` : null)};
`;