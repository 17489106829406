import {Component} from 'react';

import {Spacer} from '@nfq/spacer';
import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-awesome-styled-grid';
import styled from 'styled-components';

import Button from 'Components/action/Button';
import Link from 'Components/action/Link';
import Avatar from 'Components/layout/Avatar';
import ModalBox from 'Components/layout/ModalBox';
import {H2, Paragraph} from 'Components/layout/Text';

import {mediaUp} from 'Utils/mediaQueries';


/**
 * Info
 *
 * @class Info
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Info extends Component {
    static propTypes = {
        /** TestID for cypress testing  */
        testId: PropTypes.string
    }

    static defaultProps = {testId: null}

    /**
     * Handles appointment click.
     *
     * @memberof Info
     */
    @autobind
    handleClick() {
        window.open('https://cisco-placetel-lead.appointlet.com/s/produktberatung', '_blank');
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Info
     */
    render() {
        const {testId} = this.props;

        return (
            <ModalBox data-cy={testId}>
                <Spacer y={2} />
                <Row justify="center">
                    <Col align="center">
                        <StyledAvatarWrapper>
                            <Avatar />
                        </StyledAvatarWrapper>
                    </Col>
                </Row>
                <Spacer y={4} />
                <Row>
                    <FlexCol>
                        <H2>Ihnen ist das zu langsam?</H2>
                    </FlexCol>
                </Row>
                <Spacer y={3} />
                <Row>
                    <FlexCol>
                        <Paragraph>Lassen Sie sich direkt persönlich beraten.</Paragraph>
                        <Paragraph>Sagen Sie uns einfach, wann es Ihnen am besten passt.</Paragraph>
                    </FlexCol>
                </Row>
                <Spacer y={4.3} />
                <Row justify="center">
                    <Col justify="center" lg={3} md={3.3}>
                        <Button text="Zur Terminwahl" isBigger onClick={this.handleClick} />
                    </Col>
                </Row>
                <Spacer y={3} />
                <Row justify="center">
                    <Col align="center" xs={12}>
                        <Link href="https://www.placetel.de/">Verlassen und zur Placetel Webseite</Link>
                    </Col>
                </Row>
            </ModalBox>
        );
    }
}

export default Info;

const StyledAvatarWrapper = styled.div`
    max-width: 126px;
    width: 100%;
`;
const FlexCol = styled(Col)`
    align-items: flex-start;

    ${mediaUp('md')} {
        align-items: center;
    }
`;