import {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Close} from 'Images/icons';
import {theme as globalTheme} from 'Utils/globalStyles';
import {mediaDown, mediaUp} from 'Utils/mediaQueries';
import {ModalContext} from 'Utils/ModalContext';

/**
 * ModalBox
 *
 * @augments {Component<Props, State>}
 *
 * @class ModalBox
 * @extends {Component}
 */
class ModalBox extends Component {
    static propTypes = {children: PropTypes.node.isRequired}

    static defaultProps = {}

    static contextType = ModalContext;

    /**
     * Prevents Modal close on click in container.
     *
     * @param {Event} e Click event.
     * @memberof AdressFound
     */
    handlePreventClose(e) {
        e.stopPropagation();
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof ModalBox
     */
    render() {
        const {closeModal} = this.context;
        const {children} = this.props;

        return (
            <>
                <ModalBackground onClick={closeModal} />
                <ModalBoxElement>
                    <StyledClose onClick={closeModal}>
                        <Close color={globalTheme.colors.modalCloseIconColor} height={16} width={16} />
                    </StyledClose>
                    {children}
                </ModalBoxElement>
            </>
        );
    }
}

export default ModalBox;

const ModalBoxElement = styled.div`
    background-color: ${({theme}) => theme.colors.modalBgColor};
    border-radius: 6px;
    height: 100%;
    height: auto;
    max-width: 872px;
    padding: 48px 24px 32px;
    position: relative;
    width: calc(100% - 32px);

    ${mediaUp('md')} {
        padding: 50px 40px 59px;
    }
`;

const StyledClose = styled.div`
    cursor: pointer;
    height: 16px;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 16px;

    ${mediaUp('md')} {
        top: 40px;
        right: 38px;
        height: 18px;
        width: 18px;
    }

    svg {
        ${mediaDown('md')} {
            width: 16px;
            height: 16px;
        }

        &:hover {
            > * {
                fill: ${({theme}) => theme.colors.modalCloseIconHoverColor};
            }
        }

        > * {
            transition: fill .3s 0s ease-in-out;
        }
    }
`;

const ModalBackground = styled.div`
    background-color: rgba(0, 0, 0, 0.15);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;    
`;