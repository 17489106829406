import {Component} from 'react';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Inline} from 'Components/layout/Text';

import {ArrowRight} from 'Images/icons';
import {iconAsBackground} from 'Utils/lib';

/**
 * Link
 *
 * @class Link
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Link extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        href: PropTypes.string,
        target: PropTypes.string,
        testId: PropTypes.string
    }

    static defaultProps = {
        href: '',
        target: null,
        testId: null
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Link
     */
    render() {
        const {children, href, target, testId} = this.props;

        return (
            <NextLink href={href} passHref>
                <LinkElement data-cy={testId} rel={target ? 'noopener noreferrer' : null} target={target}>
                    <Inline size="normal" bold isHighlighted>{children}</Inline>
                </LinkElement>
            </NextLink>
        );
    }
}

export default Link;

const LinkElement = styled.a`
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    text-decoration: none;

    &::after {
        background-image: url(${({theme}) => iconAsBackground(ArrowRight, {color: theme.colors.textColorHighlight})});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 16px;
        margin-left: 8px;
        transition: background 0.3s 0s ease-in-out, margin 0.3s 0s ease-in-out;
        width: 16px;
        will-change: background, margin;
    }

    &:focus, &:active {
        outline: none;
    }

    &:hover {
        &::after {
            background-image: url(${({theme}) => iconAsBackground(ArrowRight, {color: theme.colors.textColorPrimaryHover})});
            margin-left: 16px;
            transition: background 0.3s 0s ease-in-out, margin 0.3s 0s ease-in-out;
            will-change: background, margin;
        }
        & span {
            color: ${({theme}) => theme.colors.textColorPrimaryHover};
            transition: color 0.3s 0s ease-in-out;
        }
    }
`;