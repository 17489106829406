/* eslint-disable max-len */
import DecisionTree, {hasSomeData} from '@nfq/decision-tree';

import QuestionaireStore from 'Stores/QuestionaireStore';

const SharedSubTree = new DecisionTree('/telefonie').addBranch(
    hasSomeData,
    new DecisionTree('/hardware').addBranch(
        hasSomeData,
        new DecisionTree('/berechnung').addBranch(
            data => (hasSomeData(data) && QuestionaireStore.isWebex),
            new DecisionTree('/ergebnis-webex')
        ).addBranch(
            data => (hasSomeData(data) && !QuestionaireStore.isWebex),
            new DecisionTree('/ergebnis-profi')
        )
    )
);

const SharedTree = new DecisionTree('/mobilfunk').addBranch(
    data => (hasSomeData(data) && QuestionaireStore.isWebex),
    new DecisionTree('/integrationen-webex').addBranch(
        hasSomeData,
        SharedSubTree
    )
).addBranch(
    data => (hasSomeData(data) && !QuestionaireStore.isWebex),
    new DecisionTree('/integrationen').addBranch(
        hasSomeData,
        SharedSubTree
    )
);

const Tree = new DecisionTree('/unternehmen').addBranch(
    hasSomeData,
    new DecisionTree('/messaging').addBranch(
        hasSomeData,
        new DecisionTree('/online-meetings').addBranch(
            data => (hasSomeData(data) && data.calc_meeting),
            SharedTree
        ).addBranch(
            data => (hasSomeData(data) && !data.calc_meeting),
            new DecisionTree('/online-meeting-loesungen').addBranch(
                hasSomeData,
                SharedTree
            )
        )
    )
);

export default Tree;