import {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ArrowUp} from 'Images/icons';
import {checkNumberRange} from 'Utils/lib';

const HALF = 2;
const PERC = 100;

/**
 * ProgressIndicator
 *
 * @class ProgressIndicator
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class ProgressIndicator extends Component {
    static propTypes = {
        isAllowBackClick: PropTypes.bool,
        step: PropTypes.number,
        steps: checkNumberRange(2, 10),
        testId: PropTypes.string,
        onClick: PropTypes.func
    }

    static defaultProps = {
        isAllowBackClick: true,
        onClick() {},
        step: 1,
        steps: 2,
        testId: null
    }

    /**
     * Caclulates the height of the bar.
     *
     * @returns {String} The height of the Indicator bar.
     * @memberof ProgressIndicator
     */
    calcBarHeight() {
        const {step, steps} = this.props;
        const remainingSteps = steps - 1;
        const perc = PERC / remainingSteps;

        if (step === 1) {
            return 'calc(0% + 21px)';
        } else if (step - 1 > steps / HALF) {
            return `calc(${(step - 1) * perc}% + 16px)`;
        } else if (step - 1 === (steps - 1) / HALF) {
            return `calc(${(step - 1) * perc}% + 19px)`;
        }

        return `calc(${(step - 1) * perc}% + 21px)`;
    }

    /**
     * Handles the back navigation.
     *
     * @memberof ProgressIndicator
     */
    @autobind
    handleBack() {
        const {onClick} = this.props;

        onClick();
    }

    /**
     * Renders the step indicators.
     *
     * @returns {Array} An array of indicators.
     * @memberof ProgressIndicator
     */
    renderSteps() {
        const points = [];
        const {step, steps} = this.props;
        const remainingSteps = steps - 1;
        const perc = PERC / remainingSteps;

        for (let i = 0; i < steps; i++) {
            if (i === 0) {
                points.push(<Point key={i} active={(i + 1 <= step)} top={0} />);
            } else if (i > steps / HALF) {
                points.push(<Point key={i} active={(i + 1 <= step)} bottom={PERC - i * perc} />);
            } else if (i === (steps - 1) / HALF) {
                points.push(<Point key={i} active={(i + 1 <= step)} top={i * perc} center />);
            } else {
                points.push(<Point key={i} active={(i + 1 <= step)} top={i * perc} />);
            }
        }

        return points;
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof ProgressIndicator
     */
    render() {
        const {isAllowBackClick, testId} = this.props;

        return (
            <ProgressIndicatorElement data-cy={testId}>
                <BackButton isAllowBackClick={isAllowBackClick} onClick={isAllowBackClick ? this.handleBack : null}>
                    <ArrowUp height={13} width={13} />
                </BackButton>
                <ProgressContainer>
                    <ProgressBackground />
                    <ProgressBarContainer>
                        <ProgressBar height={this.calcBarHeight()} />
                    </ProgressBarContainer>
                    <ProgressStops>
                        {this.renderSteps()}
                    </ProgressStops>
                </ProgressContainer>
            </ProgressIndicatorElement>
        );
    }
}

export default ProgressIndicator;

const ProgressIndicatorElement = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 32px;
`;

const BackButton = styled.button`
    align-items: center;
    background-color: ${({theme}) => theme.colors.primaryButton};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-bottom: 23px;
    outline: none;
    pointer-events: ${({isAllowBackClick}) => (isAllowBackClick ? 'all' : 'none')};
    transition: background-color 0.3s 0s ease-in-out;
    width: 32px;

    &:hover {
        background-color: ${({theme}) => theme.colors.buttonPrimaryHoverColor};
        transition: background-color 0.3s 0s ease-in-out;
    }

    &:focus, &:active {
        outline: none;
    }
`;

const ProgressContainer = styled.div`
    flex: 1 1 auto;
    position: relative;
`;

const ProgressBackground = styled.div`
    background-color: ${({theme}) => theme.colors.progressIndicatorBg};
    height: calc(100% - 16px);
    left: 50%;
    position: absolute;
    top: 8px;
    transform: translate(-50%, 0);
    width: 3px;
`;

const ProgressStops = styled.div`
    height: calc(100% - 16px);
    left: 50%;
    position: absolute;
    top: 8px;
    transform: translate(-50%, 0);
    width: 5px;
`;

const ProgressBarContainer = styled.div`
    height: calc(100% - 16px);
    left: 50%;
    position: absolute;
    top: 8px;
    transform: translate(-50%, 0);
    width: 12px;
`;

const ProgressBar = styled.div`
    background-color: ${({theme}) => theme.colors.progressIndicatorLine};
    border-radius: 6.5px;
    height: ${({height}) => height};
    left: 50%;
    position: absolute;
    top: -8px;
    transform: translate(-50%, 0);
    transition: height 0.5s 0s ease-in-out;
    width: 12px;
`;

const Point = styled.div.attrs(props => (
    {
        style: {
            bottom: `${props.bottom}%`,
            top: `${props.top}%`
        }
    }
))`
    background-color: ${({active, theme}) => (active ? theme.colors.containerBackground : theme.colors.primaryButton)};
    border-radius: 50%;
    height: 5px;
    position: absolute;
    width: 5px;
    ${({center}) => (center ? 'transform: translate(0, -50%);' : null)}
`;