/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * ProgressCircle Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const ProgressCircle = React.forwardRef((props, ref) => {
    const {activeColor, bgColor, className, height, strokeDasharray, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 36 36"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke={bgColor}
                strokeDasharray="100, 100"
                strokeWidth="2"
            />
            <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke={activeColor}
                strokeDasharray="0, 100"
                strokeWidth="2"
                style={{strokeDasharray: `${strokeDasharray}, 100`}}
            />
        </svg>
    );
});

ProgressCircle.displayName = 'ProgressCircle';
ProgressCircle.propTypes = {
    activeColor: PropTypes.string,
    bgColor: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    strokeDasharray: PropTypes.number,
    testId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
ProgressCircle.defaultProps = {
    activeColor: '#6AB32D',
    bgColor: '#ffffff',
    className: '',
    height: 50,
    strokeDasharray: 0,
    testId: '',
    width: 50
};