/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Close Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Close = React.forwardRef((props, ref) => {
    const {className, color, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 14 14"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color}>
                <path d="M.95.95A1.2 1.2 0 0 1 2.59.9l.06.06 10.47 10.4a1.2 1.2 0 0 1-1.63 1.76l-.06-.06L.95 2.65a1.2 1.2 0 0 1 0-1.7z" />
                <path d="M11.53.94a1.2 1.2 0 0 1 1.73 1.66l-.05.06-10.57 10.3A1.2 1.2 0 0 1 .9 11.3l.05-.06L11.53.94z" />
            </g>
        </svg>
    );
});

Close.displayName = 'Close';
Close.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    testId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Close.defaultProps = {
    className: '',
    color: '#1C9EE2',
    height: 14,
    testId: '',
    width: 14
};