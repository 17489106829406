import {Component} from 'react';

import {autobind} from 'core-decorators';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ArrowUp, ProgressCircle} from 'Images/icons';
import {theme as globalTheme} from 'Utils/globalStyles';
import {mediaUp} from 'Utils/mediaQueries';

/**
 * MobileProgress
 *
 * @class MobileProgress
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class MobileProgress extends Component {
    static propTypes = {
        step: PropTypes.number,
        steps: PropTypes.number,
        /** TestID for cypress testing  */
        testId: PropTypes.string,
        onClick: PropTypes.func
    }

    static defaultProps = {
        onClick() {},
        step: 0,
        steps: 0,
        testId: null
    }

    /**
     * Handles the back navigation.
     *
     * @memberof ProgressIndicator
     */
    @autobind
    handleBack() {
        const {onClick} = this.props;

        if (onClick) {
            onClick();
        }
    }

    /**
     * Returns the step percentage
     *
     * @returns {Number} Step
     * @memberof MobileProgress
     */
    @autobind
    calculateRadius() {
        const {step, steps} = this.props;
        // eslint-disable-next-line @nfq/no-magic-numbers
        const percent = Math.round((100 / steps)) * step + 2;

        return percent;
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof MobileProgress
     */
    render() {
        const {testId} = this.props;

        return (
            <MobileProgressElement data-cy={testId} onClick={this.handleBack}>
                <IconWrapper>
                    <ArrowUp color={globalTheme.colors.mobileIndicatorIconColor} height={16} width={16} />
                </IconWrapper>
                <SVGWrapper>
                    <ProgressCircle strokeDasharray={this.calculateRadius()} />
                </SVGWrapper>
            </MobileProgressElement>
        );
    }
}

export default MobileProgress;

const MobileProgressElement = styled.div`
    cursor: pointer;
    height: 50px;
    width: 50px;

    ${mediaUp('md')} {
        height: 60px;
        width: 60px;
    }
`;

const IconWrapper = styled.div`
    left: 50%;
    position: absolute;
    top: calc(50% + 2px);
    transform: translate(-50%, -50%);
`;

const SVGWrapper = styled.div`
    height: inherit;
    width: inherit;

    ${mediaUp('md')} {
        height: inherit;
        width: inherit;
    }

    svg {
        left: 0;
        position: absolute;
        top: 0;

        ${mediaUp('md')} {
            height: inherit;
            width: inherit;
        }

        * {
            transition: stroke-dasharray ease-in-out .5s;
        }
    }
`;