/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * ArrowUp Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const ArrowUp = React.forwardRef((props, ref) => {
    const {className, color, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 10 14"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color}>
                <path d="M4.29 1.16a1.02 1.02 0 0 1 1.8.7l-.06 10.31c0 .6-.44 1.05-1.04 1.05-.6 0-1.05-.45-1.05-1.05L4 1.85c0-.3.15-.54.3-.7z" />
                <path d="M.8 4.55 4.24 1.1c.4-.4 1.1-.4 1.5 0l3.43 3.44c.4.4.4 1.1 0 1.49-.4.4-1.1.4-1.5 0L5 3.34l-2.7 2.7c-.4.4-1.1.4-1.49 0-.4-.4-.4-1.1 0-1.5z" />
            </g>
        </svg>
    );
});

ArrowUp.displayName = 'ArrowUp';
ArrowUp.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    testId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
ArrowUp.defaultProps = {
    className: '',
    color: '#ffffff',
    height: 14,
    testId: '',
    width: 10
};