import {Component} from 'react';

import Image from 'next/image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import KarinaAvatar from 'Images/karina_avatar';
import {mediaUp} from 'Utils/mediaQueries';

/**
 * Avatar
 *
 * @class Avatar
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Avatar extends Component {
    static propTypes = {
        isBigger: PropTypes.bool,
        testId: PropTypes.string
    }

    static defaultProps = {
        isBigger: false,
        testId: null
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Avatar
     */
    render() {
        const {isBigger, testId} = this.props;

        return (
            <AvatarElement data-cy={testId} isBigger={isBigger}>
                <Image
                    alt="Avatar Image"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center center"
                    placeholder="blur"
                    src={KarinaAvatar}
                />
            </AvatarElement>
        );
    }
}

export default Avatar;

const AvatarElement = styled.div`
    border-radius: 50%;
    height: 120px;
    margin: 0 auto;
    max-height: ${({isBigger}) => (isBigger ? '120px' : '80px')};
    max-width: ${({isBigger}) => (isBigger ? '120px' : '80px')};
    overflow: hidden;
    position: relative;
    width: 120px;

    ${mediaUp('lg')} {
        max-height: 120px;
        max-width: 120px;
    }
`;