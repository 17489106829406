import {Component} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Inline} from 'Components/layout/Text';

import {mediaUp} from 'Utils/mediaQueries';

/**
 * Button
 *
 * @class Button
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Button extends Component {
    static propTypes = {
        /** Defines which icon component will be shown. */
        icon: PropTypes.object,
        /** Defines if the button is active. */
        isActive: PropTypes.bool,
        /** Defines if the button has bigger padding and font size. */
        isBigger: PropTypes.bool,
        /** Defines if the button is disabled. */
        isDisabled: PropTypes.bool,
        /** TestID for cypress testing.  */
        testId: PropTypes.string,
        /** Text to be shown in button. */
        text: PropTypes.string,
        /** The button type */
        type: PropTypes.string,
        /** onClick handler. */
        onClick: PropTypes.func
    }

    static defaultProps = {
        icon: null,
        isActive: false,
        isBigger: false,
        isDisabled: false,
        onClick() {},
        testId: null,
        text: 'Button Text',
        type: 'button'
    }

    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Button
     */
    render() {
        const {icon, isActive, isBigger, isDisabled, onClick, testId, text, type} = this.props;

        return (
            <ButtonElement
                data-cy={testId}
                isActive={isActive}
                isBigger={isBigger}
                isDisabled={isDisabled}
                type={type}
                onClick={isDisabled ? null : onClick}
            >
                {icon}
                <Inline size={isBigger ? 'normal' : 'small'} bold isInverted>{text}</Inline>
            </ButtonElement>
        );
    }
}

export default Button;

const ButtonElement = styled.button`
    background-color: ${({isActive, theme}) => (isActive ? theme.colors.buttonActiveBgColor : theme.colors.buttonPrimaryBgColor)};
    border: 0;
    border-radius: 100px;
    min-width: ${({isBigger}) => (isBigger ? '221px' : '200px')};;
    outline: none;
    padding: ${({isBigger}) => (isBigger ? '18px 35px 18px 33px' : '15px 65px 16px 66px')};
    transition: background-color 0.3s 0s ease-in-out;
    width: 100%;

    ${({isDisabled}) => (isDisabled ? 'opacity: .5; pointer-events: none;' : null)}

    &:hover {
        background-color: ${({isActive, theme}) => (isActive ? theme.colors.buttonActiveBgColor : theme.colors.buttonPrimaryHoverColor)};
        cursor: pointer;
    }

    ${mediaUp('md')} {
        max-width: 287px;
    }

    svg {
        margin-right: 4px;
        transform: translate(-3px, 2px);
    }
`;