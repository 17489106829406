/* eslint-disable max-lines */
import {Component} from 'react';

import {autobind} from 'core-decorators';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseButton from 'Components/action/CloseButton';
import ExternalLink from 'Components/action/ExternalLink';
import MobileProgress from 'Components/action/MobileProgress';
import CloseProcess from 'Components/content/modal/CloseProcess';
import {Paragraph} from 'Components/layout/Text';
import QuestionaireStore from 'Stores/QuestionaireStore';

import {ArrowRestart, PlacetelLogo} from 'Images/icons';
import {mediaUp} from 'Utils/mediaQueries';
import {ModalContext} from 'Utils/ModalContext';

const RIGHT_DELAY = 0.5;
const LEFT_DELAY = 0.5;

const LogoWhite = {
    imageLeft: {
        opacity: 1,
        transition: {
            // eslint-disable-next-line @nfq/no-magic-numbers
            delay: LEFT_DELAY + 0.2,
            duration: 0.2
        }
    },
    imageRight: {
        opacity: 0,
        transition: {
            delay: RIGHT_DELAY,
            duration: 0.2
        }
    },
    noImage: {
        opacity: 0,
        transition: {
            delay: RIGHT_DELAY,
            duration: 0.2
        }
    }
};


const LogoColor = {
    imageLeft: {
        opacity: 0,
        transition: {
            delay: LEFT_DELAY,
            duration: 0.2
        }
    },
    imageRight: {
        opacity: 1,
        transition: {
            // eslint-disable-next-line @nfq/no-magic-numbers
            delay: RIGHT_DELAY + 0.2,
            duration: 0.2
        }
    },
    noImage: {
        opacity: 1,
        transition: {
            // eslint-disable-next-line @nfq/no-magic-numbers
            delay: RIGHT_DELAY + 0.2,
            duration: 0.2
        }
    }
};

/**
 * Navigation
 *
 * @class Navigation
 * @augments {Component<Props, State>}
 * @extends {Component}
 */
class Navigation extends Component {
    static propTypes = {
        router: PropTypes.object.isRequired,
        isAllowBackClick: PropTypes.bool,
        // eslint-disable-next-line react/boolean-prop-naming
        showNav: PropTypes.bool,
        step: PropTypes.number,
        /** TestID for cypress testing  */
        testId: PropTypes.string,
        onHandleBack: PropTypes.func
    }

    static defaultProps = {
        isAllowBackClick: false,
        onHandleBack() {},
        showNav: false,
        step: 0,
        testId: null
    }

    static contextType = ModalContext;


    /**
     * Creates an instance of Navigation.
     *
     * @param {Object} props Component props.
     * @memberof Navigation
     */
    constructor(props) {
        super(props);

        this.state = {
            showCloseButton: false,
            showDesktopLogo: true,
            showMobileLogo: false,
            showMobileProgress: false,
            showRestartButton: false
        };

        // eslint-disable-next-line react/destructuring-assignment
        this.oldRouter = this.props.router.route;
    }

    /**
     * Handles the component mounting.
     *
     * @memberof Navigation
     */
    componentDidMount() {
        window.addEventListener('resize', this.handleRendering);
        this.handleRendering();
    }

    /**
     * Handles the component update.
     *
     * @memberof Carousel
     */
    componentDidUpdate() {
        const {router} = this.props;
        const {route} = router;

        if (route !== this.oldRouter) {
            this.oldRouter = route;
            this.handleRendering();
        }
    }

    /**
     * Handles the component unmounting.
     *
     * @memberof Navigation
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleRendering);
    }

    /**
     * Defines which nav item will be shown.
     *
     * @memberof Navigation
     */
    @autobind
    handleRendering() {
        const {router} = this.props;
        const breakpoint = 992;
        const pagesForRestart = ['/ergebnis-profi', '/ergebnis-webex', '/danke'];

        if (window.outerWidth <= breakpoint) {
            if (router.route === '/' || router.route === '/danke') {
                this.setState({
                    showDesktopLogo: false,
                    showMobileLogo: true,
                    showMobileProgress: false,
                    showRestartButton: false
                });
            } else if (pagesForRestart.includes(router.route)) {
                this.setState({
                    showDesktopLogo: true,
                    showMobileLogo: false,
                    showMobileProgress: false,
                    showRestartButton: true
                });
            } else {
                this.setState({
                    showDesktopLogo: false,
                    showMobileLogo: false,
                    showMobileProgress: true,
                    showRestartButton: false
                });
            }
        } else {
            this.setState({
                showDesktopLogo: true,
                showMobileLogo: false,
                showMobileProgress: false,
                showRestartButton: false
            });
        }

        if (router.route === '/berechnung') {
            this.setState({
                showCloseButton: false,
                showMobileLogo: true,
                showMobileProgress: false
            });
        } else {
            this.setState({showCloseButton: true});
        }
    }

    /**
     * Renders the modal component sent as a prop.
     *
     * @memberof Home
     */
    @autobind
    handleShowingCloseModal() {
        const {router} = this.props;
        const {closeModal, openModal} = this.context;

        if (router.route === '/danke') {
            router.push('https://placetel.de');
        } else {
            openModal(<CloseProcess closeModal={closeModal} />);
        }
    }

    /**
     * Handles the back button.
     *
     * @memberof PageLayout
     */
    @autobind
    handleBack() {
        const {onHandleBack} = this.props;

        onHandleBack();
    }

    /**
     * Restarts the app.
     *
     * @memberof Navigation
     */
    @autobind
    handleOnRestart() {
        const {router} = this.props;
        const {resetAnswers} = QuestionaireStore;

        resetAnswers();


        router.push('/');
    }


    /**
     * Renders the Component.
     *
     * @returns {JSX} Component.
     * @memberof Navigation
     */
    render() {
        const {isAllowBackClick, showNav, step, testId} = this.props;
        const {showCloseButton, showDesktopLogo, showMobileLogo, showMobileProgress, showRestartButton} = this.state;

        return (
            <NavigationElement data-cy={testId} showNav={showNav}>
                <StyledCloseButtonWrapper show={showCloseButton}>
                    <CloseButton onClick={this.handleShowingCloseModal} />
                </StyledCloseButtonWrapper>

                {showDesktopLogo
                    ? (
                        <>
                            <ExternalLink href="https://placetel.de">
                                <DesktopPlacetelAnimate height={32} variants={LogoColor} width={145} />
                            </ExternalLink>

                            <ExternalLink href="https://placetel.de">
                                <DesktopPlacetelAnimate
                                    circleColor="#ffffff"
                                    height={32}
                                    lineColor="#ffffff"
                                    textColor="#ffffff"
                                    variants={LogoWhite}
                                    width={145}
                                />
                            </ExternalLink>
                        </>
                    )
                    : (
                        <>
                            <MobileLogoWrapper isVisible={showMobileLogo}>
                                <PlacetelAnimate
                                    height={32}
                                    width={145}
                                />
                            </MobileLogoWrapper>

                            <MobileProgressWrapper isVisible={showMobileProgress}>
                                <MobileProgress
                                    step={step}
                                    steps={7}
                                    onClick={isAllowBackClick ? this.handleBack : null}
                                />
                            </MobileProgressWrapper>
                        </>
                    )}

                {showRestartButton
                    && (
                        <RestartWrapper isVisible={showRestartButton} onClick={this.handleOnRestart}>
                            <span>
                                <ArrowRestart height={20} width={20} />
                            </span>
                            <Paragraph>Beratung neu starten</Paragraph>
                        </RestartWrapper>
                    )}
            </NavigationElement>
        );
    }
}

export default Navigation;

const NavigationElement = styled.div`
    position: absolute;
    transform: ${({showNav}) => (showNav ? 'translateY(0px)' : 'translateY(-100px)')};
    transition: transform .3s ease-in-out;
    width: 100vw;
    z-index: 10;
`;

const RestartWrapper = styled.div`
    ${({isVisible}) => (isVisible ? null : 'transform: translateY(-100px);')};
    align-items: center;
    cursor: pointer;
    display: flex;
    display: flex;
    left: 16px;
    position: fixed;
    top: 24px;
    z-index: 8;

    ${mediaUp('md')} {
        display: none;
    }

    &:hover {
        p {
            color: ${({theme}) => theme.colors.restartIconActiveColor};
        }

        svg {
            > * {
                stroke: ${({theme}) => theme.colors.restartIconActiveColor};
            }
        }
    }

    p {
        color: ${({theme}) => theme.colors.restartTextColor};
        margin-left: 12px;
    }

    > span {
        align-items: center;
        background-color: ${({theme}) => theme.colors.restartIconBgColor};
        border: 1px solid ${({theme}) => theme.colors.pageBackground};
        border-radius: 50px;
        border-radius: 50px;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 50px;
    }
`;

const MobileProgressWrapper = styled.div`
    ${({isVisible}) => (isVisible ? null : 'transform: translateY(-10em);')};
    left: 15px;
    position: fixed;
    top: 24px;
    transition: transform ease-in-out .5s;
    z-index: 8;
`;

const PlacetelAnimate = styled(motion(PlacetelLogo))`
    left: 16px;
    opacity: 1;
    position: fixed;
    position: absolute;
    top: 36px;
    transition: transform .31s ease-in-out;
    z-index: 20;

    ${mediaUp('md')}{
        left: 64px;
        top: 52px;
    }
`;

const DesktopPlacetelAnimate = styled(motion(PlacetelAnimate))`
    display: none;

    ${mediaUp('md')}{
        display: block;
    }
`;

const MobileLogoWrapper = styled(motion(PlacetelLogo))`
    // eslint-disable-next-line better-styled-components/sort-declarations-alphabetically
    transition: transform ease-in-out .4s 1.55s;
    ${({isVisible}) => (isVisible ? null : 'transition: transform ease-in-out .3s; transform: translateY(-100px);')};
    left: 16px;
    opacity: 1;
    position: fixed;
    top: 36px;
    z-index: 20;

    ${mediaUp('md')}{
        left: 64px;
        top: 52px;
    }
`;

const StyledCloseButtonWrapper = styled.div`
    border: 1px solid ${({theme}) => theme.colors.pageBackground};
    border-radius: 50px;
    left: auto;
    opacity: ${({show}) => (show ? '1' : '0')};
    position: absolute;
    right: ${({show}) => (show ? '16px' : '-30px')};
    top: 24px;
    transition: transform .3s ease-in-out, ${({show}) => (show ? 'opacity ease-in-out .3s, right ease-in-out .2s 0s' : 'opacity ease-in-out .3s, right ease-in-out 0s 2s')};
    z-index: 10;

    ${mediaUp('md')}{
        border: none;
    }

    ${mediaUp('lg')}{
        right: ${({show}) => (show ? '48px' : '70px')};
        top: 40px;
    }
`;