/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * ArrowRestart Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const ArrowRestart = React.forwardRef((props, ref) => {
    const {className, color, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 23 19"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeWidth="1.8">
                <path d="M11.48 1.6a8.12 8.12 0 0 1 9.64 6.23 8.12 8.12 0 0 1-6.28 9.61 8.12 8.12 0 0 1-9.57-9.9" />
                <path d="m1.01 11.23 3.92-4.3 4.48 3.83" strokeLinejoin="round" />
            </g>
        </svg>
    );
});

ArrowRestart.displayName = 'ArrowRestart';
ArrowRestart.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    testId: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
ArrowRestart.defaultProps = {
    className: '',
    color: '#9B9B9B',
    height: 19,
    testId: '',
    width: 23
};